import React, { useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControl, Link, FormControlLabel, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';

const TipoDocumento = {
    type1: "Presupuesto",
    type2: "Certificación"
};

const ClasificacionOptions = {
    clasificacion1: "Empresa",
    clasificacion2: "Zona",
    clasificacion3: "Capitulo"
};

const PrecioMostradoOptions = {
    precio1: "Coeficiente precio",
    precio2: "Precio",
    precio3: "Sin precio"
};

const PrecioMostradoOptionsCertificacion = {
    precio1: "Coeficiente precio",
    precio2: "Precio",
};

function GeneraDocumento() {
    const [formValues, setFormValues] = useState({
        tipo_documento: '',
        nombre_documento_resultante: '',
        id_spreadsheet: '',
        id_destino: '',
        clasificacion: '',
        seleccion: [],
        precio_mostrado: '',
        quiero_titulo_fila: false
    });
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSelectionChange = (event) => {
        const { value } = event.target;
        const listValues = value.split(',').map(item => item.trim());
        setFormValues({ ...formValues, seleccion: listValues });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setShowOverlay(true);
        try {
            const response = await axios.post('http://franciscojerezgestion.com/genera_documento', JSON.stringify(formValues), {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            setResponse(response.data);
        } catch (error) {
            console.error('Error submitting form data:', error);
            setResponse('Failed to fetch data. Check console for errors.');
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        setShowOverlay(false);
        setResponse(null);
    };

    const renderResponse = (response) => {
        if (response.message) {
            return <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }}>
                {response.message}
            </Typography>;
        } else {
            return (
                <>
                    {response.doc_link && <Link href={response.doc_link} target="_blank" rel="noopener noreferrer" sx={{ mt: 1 }}>Document Link</Link>}
                    {response.pdf_link && <Link href={response.pdf_link} target="_blank" rel="noopener noreferrer" sx={{ mt: 1 }}>PDF Link</Link>}
                    {response.destino_link && <Link href={response.destino_link} target="_blank" rel="noopener noreferrer" sx={{ mt: 1 }}>Carpeta Destino Link</Link>}
                </>
            );
        }
    };

    const isSubmitDisabled = !formValues.tipo_documento || !formValues.nombre_documento_resultante || !formValues.id_spreadsheet || !formValues.id_destino || !formValues.clasificacion;

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" maxWidth="50%">
            <Paper elevation={3} style={{ padding: '2rem', width: 'fit-content' }}>
                <Typography variant="h5" component="h1" marginBottom={2}>
                    Genera Documento
                </Typography>
                <form onSubmit={handleSubmit}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Tipo de Documento</InputLabel>
                        <Select
                            name="tipo_documento"
                            value={formValues.tipo_documento}
                            onChange={handleInputChange}
                        >
                            {Object.entries(TipoDocumento).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Nombre del Documento Resultante"
                        name="nombre_documento_resultante"
                        value={formValues.nombre_documento_resultante}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Id del Spreadsheet"
                        name="id_spreadsheet"
                        value={formValues.id_spreadsheet}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Id Destino"
                        name="id_destino"
                        value={formValues.id_destino}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Clasificación</InputLabel>
                        <Select
                            name="clasificacion"
                            value={formValues.clasificacion}
                            onChange={handleInputChange}
                        >
                            {Object.entries(ClasificacionOptions).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Selección"
                        name="seleccion"
                        onChange={handleSelectionChange}
                        helperText="Escribe los valores seleccionados separados por comas, igual que en la spreadsheet"
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal" disabled = {formValues.tipo_documento === ''}>
                        <InputLabel>Precio Mostrado</InputLabel>
                        <Select
                            name="precio_mostrado"
                            value={formValues.precio_mostrado !== '' ? formValues.precio_mostrado : 'Sin precio'}
                            onChange={handleInputChange}
                        >
                            {Object.entries(formValues.tipo_documento === "Presupuesto" ? PrecioMostradoOptions : PrecioMostradoOptionsCertificacion).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                    control={
                        <Checkbox
                            checked={formValues.quiero_titulo_fila}
                            onChange={(event) => setFormValues({ ...formValues, quiero_titulo_fila: event.target.checked })}
                            name="quiero_titulo_fila"
                        />
                    }
                    label="¿Quieres título en la fila?"
                    />
                    
                    <Box marginY={2}>
                        <Button type="submit" variant="contained" color="primary" disabled={isSubmitDisabled}>
                            Generar
                        </Button>
                    </Box>
                </form>
            </Paper>
            <Modal
                open={showOverlay}
                onClose={closeModal}
                aria-labelledby="loading-modal-title"
                aria-describedby="loading-modal-description"
            >
                <Box sx={modalStyle}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            {response && renderResponse(response)}

                            <Box sx={{ mt: 2 }} />
                            <Button onClick={closeModal} variant="contained" color="primary">OK</Button>
                        </>
                    )}
                </Box>
            </Modal>
        </Box>
    );
}

export default GeneraDocumento;
