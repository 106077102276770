import React, { useState, useEffect } from 'react';
import './App.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import GeneraDocumento from './GeneraDocumento';
import axios from 'axios';

function App() {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [response, setResponse] = useState('');
  useEffect(() => {
    // Check local storage to see if the user has already entered the correct password
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (isAuthenticated) {
      setAuthenticated(true);
    } else {
      setOpen(true);
    }
  }, []);

  const handleClose = async (event) => {
    let response = null
    try {
        response = await axios.post('http://franciscojerezgestion.com/check', JSON.stringify({"password": password}), {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        setResponse(response.data.message);
	
	
    } catch (error) {
        setResponse('Failed to fetch data. Check console for errors.');
    } finally {
	if (response.data.message === true) {
          localStorage.setItem('isAuthenticated', 'true');
          setAuthenticated(true);
          setOpen(false);
        } else {
          alert('Incorrect password');
        }
    }
  };

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className="App">
      <header className="App-header">
        {authenticated ? (
          <GeneraDocumento />
        ) : (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Authentication Required</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the password to access the application.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                variant="standard"
                onChange={handleChange}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleClose();
                  }
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Submit</Button>
            </DialogActions>
          </Dialog>
        )}
      </header>
    </div>
  );
}

export default App;
